// theme.js or theme.ts
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { createTypography } from 'common/utils/create-typography';

let theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            fontSize: '18px',
            color: '#0047FD',
            fontWeight: 600,
            textTransform: 'none'
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            fontSize: [2, 2, 2.23, 2.5, 2.75].map((size) => `${size * 8}px`),
            lineHeight: [2, 2, 2.23, 2.5, 2.75]
              .map((size) => size * 1.5)
              .map((size) => size * 8)
              .map((size) => `${size}px`),
            fontWeight: 600,
            textTransform: 'none',
            borderRadius: 8,
            backgroundColor: '#1A61FF',
            '&:hover': {
              backgroundColor: '#0047FD'
            }
          }
        }
      ]
    }
  }
});

theme.typography.fontFamily = ['Open Sans', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'].join(',');
theme.typography.h1 = createTypography(theme, [3, 4, 5, 6, 7], 700);
theme.typography.h2 = createTypography(theme, [3, 3.5, 4.5, 5, 6], 700);
theme.typography.h3 = createTypography(theme, [2.5, 3, 3.75, 4, 5], 700);
theme.typography.h4 = createTypography(theme, [2.25, 2.5, 3, 3.5, 3.75], 600);
theme.typography.h5 = createTypography(theme, [2, 2, 2.5, 2.75, 2.75], 700);
theme.typography.h6 = createTypography(theme, [1, 2, 3, 4, 4], 700);
theme.typography.body1 = createTypography(theme, [2.25, 2.5, 2.75, 3, 3.5], 400);
theme.typography.body2 = createTypography(theme, [2, 2, 2.23, 2.5, 2.75], 400);
theme.typography.caption = createTypography(theme, [1, 1, 2, 3, 3], 400);
theme.typography.overline = createTypography(theme, [1, 1, 2, 3, 3], 400);
theme.typography.subtitle1 = createTypography(theme, [2, 2, 2.1, 2.25, 2.5], 400);
theme.typography.subtitle2 = createTypography(theme, [1.75, 1.9, 2, 2.25, 2.25], 400);

export default theme;
