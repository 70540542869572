import { Language } from '@reslify/i18n';
export const CountryDataConfig = {
    US: {
        isoCode: 'us',
        currency: 'USD',
        defaultLanguage: Language.EN,
        defaultDateFormat: 'MDY',
        defaultTimeFormat: 'H12',
        defaultLocale: 'EN_US'
    },
    AU: {
        isoCode: 'au',
        currency: 'AUD',
        defaultLanguage: Language.EN,
        defaultDateFormat: 'DMY',
        defaultTimeFormat: 'H12',
        defaultLocale: 'EN_AU'
    },
    TR: {
        isoCode: 'tr',
        currency: 'TRY',
        defaultLanguage: Language.TR,
        defaultDateFormat: 'DMY',
        defaultTimeFormat: 'H24',
        defaultLocale: 'TR_TR'
    }
};
