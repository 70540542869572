/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// FormatJS Polyfill imports - Used for intl number formatting
// import '@formatjs/intl-locale/polyfill';
// import '@formatjs/intl-numberformat/locale-data/en';
// import '@formatjs/intl-numberformat/polyfill';
import { UICoreProvider } from '@infra-go/ui-core';
import browserLang from 'browser-lang';
import { GatsbyBrowser, withPrefix } from 'gatsby';
import React from 'react';



import { OrganisationCountry, isOrganisationCountry } from '@reslify/graphql';
import { IntlProviderSync } from '@reslify/i18n';
import { getCountryData } from '@reslify/utils';



import theme from './src/common/theme';
import { isLanguage } from './src/common/utils/is-language';
import { IS_DEV } from './src/utils/env.constants';
import { defaultCountryCode, supportedCountries } from './src/utils/language.constants';


// Prevents <Layout/> from unmounting on page transitions

// Prevents <Layout/> from unmounting on page transitions

// Prevents <Layout/> from unmounting on page transitions
// https://www.gatsbyjs.com/docs/layout-components/#how-to-prevent-layout-components-from-unmounting
// @ts-ignore: returning `null` is not accepted by the `GatsbyBrowser` type def.
export const wrapPageElement: GatsbyBrowser<any, any>['wrapPageElement'] = ({ element, props }) => {
  const { location, pageContext } = props;
  const { pathname, search } = location;
  const { originalPath } = pageContext;

  const [, pathLocale] = pathname.split('/');

  // client side redirect on paths that don't have a locale in them.
  // Most useful on dev env where we don't have server redirects
  if (!isLanguage(pathLocale) && IS_DEV) {
    let detected =
      window.localStorage.getItem('reslify-com-language') ||
      browserLang({
        languages: supportedCountries,
        fallback: defaultCountryCode
      });

    if (!isLanguage(detected)) {
      detected = defaultCountryCode;
    }

    const queryParams = search || '';
    const newUrl = withPrefix(`/${detected}${originalPath}${queryParams}`);
    window.localStorage.setItem('reslify-com-language', detected);

    window.location.replace(newUrl);
  }

  const countryCode = pageContext.locale ? pageContext.locale.toUpperCase() : 'US';
  const country: OrganisationCountry = isOrganisationCountry(countryCode) ? countryCode : 'US';
  const language = getCountryData(country).defaultLanguage;

  return (
    <IntlProviderSync language={language}>
      <UICoreProvider
        themeOptions={{
          theme: theme
        }}
      >
        {element}
      </UICoreProvider>
    </IntlProviderSync>
  );
};

// export const wrapRootElement = ({ element }: any) => {
//   const cache = createCache({ key: 'custom' });
//
//   return (
//     <CacheProvider value={cache}>
//       <IntlProvider>
//         <UICoreProvider
//           themeOptions={{
//             theme: theme
//           }}
//         >
//           {element}
//         </UICoreProvider>
//       </IntlProvider>
//     </CacheProvider>
//   );
// };
