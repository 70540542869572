import { Theme } from '@mui/material/styles';

export function createTypography(theme: Theme, fontSizes: number[], fontWeight: number) {
  if (!Array.isArray(fontSizes) || fontSizes.length !== 5) {
    throw new Error('Input must be an array of five font sizes.');
  }

  const breakpoints = ['sm', 'md', 'lg', 'xl'] as const;
  const typography: Record<string, any> = {
    fontSize: `${fontSizes[0] * 8}px`,
    fontWeight: fontWeight,
    lineHeight: 1.8
  };

  breakpoints.forEach((bp, index) => {
    typography[theme.breakpoints.up(bp)] = {
      fontSize: `${fontSizes[index + 1] * 8}px`
    };
  });

  return typography;
}
