const countryMap = {
  US: true,
  TR: true,
  AU: true
};
const countries = Object.keys(countryMap);
function isOrganisationCountry(countryCode) {
  return countries.includes(countryCode);
}
export {
  countries,
  isOrganisationCountry
};
